<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2025-02-15 15:40 -->
<!-- Description:  -->
<template>
  <select class="custom-select" :value="value" @change="$emit('input', $event.target.value)">
    <option></option>
    <option v-for="(l, i) in list" :key="i" :value="l">{{ l }} minutos</option>
  </select>
</template>
<script>

// script content
export default {
  name: "DurationSelect",
  components: {
    //
  },

  // directives

  props: {
    interval: {
      default: 5,
      required: true,
    },
    maxDuration: {
      // en minutos la duracion maxima
      default: 240
    },
    value: {},
  },

  data: () => ({
    list: [],
  }),

  computed: {
    // 
  },

  // watch: {},

  beforeMount() {
    let results = [];
    let currTime = this.interval;
    while (currTime <= this.maxDuration && this.interval > 0) {
      results.push(currTime);
      currTime += this.interval;
    }
    this.list = results;
  },

  methods: {
    //
    //
    //
  }
};
</script>
<style scoped></style>
