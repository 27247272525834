/**
 * Author: Wilson Pilco Nuñez
 * Email: wilsonaux1@gmail.com
 * Created at: 2025-02-14 21:44
 */
import {myMoment} from "src/lt/utils/myMoment.js";
import {DentalClinicService} from "../service";
import {AdminService} from "src/admin-module/AdminService.js";
import {dayTimes} from "../../base-module/day-times";

class ScheduleService {
  static async getNextBusyTime(employee_id, ini_date, overLoad, unit_time) {
    // let iniMoment = myMoment(ini_date);
    // let [quotes, schedules, disableTimes] = await Promise.all([
    //   DentalClinicService.getQuotes({
    //     employee_id: employee_id,
    //     ini_date,
    //     end_date: ini_date,
    //     per_page: 9999,
    //     group: overLoad ? 2 : null,
    //   }),
    //   AdminService.getEmployeeSchedules({
    //     employee_id: employee_id,
    //     per_page: 1,
    //     date: ini_date,
    //   }),
    //   DentalClinicService.getDisableScheduleTimes({
    //     employee_id: employee_id,
    //     ini_time: ini_date,
    //     end_time: ini_date,
    //   })
    // ]);
    // let times = {};
    // let currentSchedule = schedules.data.length >= 1 ? schedules.data[0] : null
    // let timeInit = null;
    // let timeEnd = null;
    // if (currentSchedule) {
    //   itMoment = iniMoment.clone();
    //   do {
    //     itMoment.add(unit_time, "minute");
    //     let currentDateTime = itMoment.format("YYYY-MM-DD HH:mm:ss");
    //     // buscando citas
    //   } while (itMoment.diff(iniMoment, "day") > 0) 
    //     // se repite 7 veces
    //     let currentTimes = [];
    //     for (let time of day_times) {
    //       if (
    //         !currentSchedule[`d${iniMoment.day()}`] ||
    //         currentSchedule.times
    //           .filter(x => x.day_of_week == iniMoment.day() && x.ini_time && x.end_time && x.ini_time != x.end_time)
    //           .filter(x => x.ini_time <= time.value && x.end_time > time.value)
    //           .length == 0
    //       ) {
    //         continue;
    //       }
    //       if (quotes.data
    //         .filter(x => x.status_id !== 10 && x.status_id !== 9)
    //         .filter(x => x.ini_date <= currentDateTime && x.end_date > currentDateTime).length > 0) {
    //         continue;
    //       }
    //       if (disableTimes.data.filter(x => x.ini_time <= currentDateTime && x.end_time > currentDateTime).length > 0) {
    //         continue;
    //       }
    //       currentTimes.push({
    //         day: iniMoment.day(),
    //         time: time.time,
    //         value: time.value,
    //         datetime: currentDateTime,
    //       });
    //     }
    //     times[iniMoment.day()] = currentTimes; // se guardan los tiempos
    //     iniMoment.add(1, "day");
    //   }
    // }

    // return {
    //   args: arguments,
    //   timeInit,
    //   timeEnd,
    //   times,
    // };
  }

  static async generateAvailableSchedule(employee_id, ini_date, overLoad, unit_time) {
    let iniMoment = myMoment(ini_date);
    let end_date = iniMoment.clone().add(7, "day").format('YYYY-MM-DD');
    let [quotes, schedules, disableTimes] = await Promise.all([
      DentalClinicService.getQuotes({
        employee_id: employee_id,
        ini_date,
        end_date,
        per_page: 9999,
        group: overLoad ? 2 : null,
      }),
      AdminService.getEmployeeSchedules({
        employee_id: employee_id,
        per_page: 1,
        date: ini_date,
      }),
      DentalClinicService.getDisableScheduleTimes({
        employee_id: employee_id,
        ini_time: ini_date,
        end_time: end_date,
      })
    ]);
    let times = {};
    let currentSchedule = schedules.data.length >= 1 ? schedules.data[0] : null
    let timeInit = null;
    let timeEnd = null;
    if (currentSchedule) {
      // obtener hora inicio
      timeInit = currentSchedule.times.reduce((p, c) => {
        return c.ini_time < p ? c.ini_time : p
      }, "23:59:59");
      // obtener hora fin
      timeEnd = currentSchedule.times.reduce((p, c) => {
        return c.end_time > p ? c.end_time : p
      }, "00:00:00");
      let day_times = dayTimes.filter((x, y) => {
        return (
          y % unit_time == 0 &&
          (timeInit ? timeInit <= x.value : true) &&
          (timeEnd ? timeEnd >= x.value : true)
        );
      });
      for (let day in Array.from(Array(7))) {
        // se repite 7 veces
        let currentTimes = [];
        for (let time of day_times) {
          let currentDateTime = `${iniMoment.format("YYYY-MM-DD")} ${time.value}`;
          if (
            !currentSchedule[`d${iniMoment.day()}`] ||
            currentSchedule.times
              .filter(x => x.day_of_week == iniMoment.day() && x.ini_time && x.end_time && x.ini_time != x.end_time)
              .filter(x => x.ini_time <= time.value && x.end_time > time.value)
              .length == 0
          ) {
            continue;
          }
          if (quotes.data
            .filter(x => x.status_id !== 10 && x.status_id !== 9)
            .filter(x => x.ini_date <= currentDateTime && x.end_date > currentDateTime).length > 0) {
            continue;
          }
          if (disableTimes.data.filter(x => x.ini_time <= currentDateTime && x.end_time > currentDateTime).length > 0) {
            continue;
          }
          currentTimes.push({
            day: iniMoment.day(),
            time: time.time,
            value: time.value,
            datetime: currentDateTime,
          });
        }
        times[iniMoment.day()] = currentTimes; // se guardan los tiempos
        iniMoment.add(1, "day");
      }
    }

    return {
      args: arguments,
      timeInit,
      timeEnd,
      times,
    };
  }

  static async generateWeeklySchedule(employee_id, ini_date, end_date, overLoad, unit_time) {
    // conseguir las citas
    let [quotes, schedules, disableTimes] = await Promise.all([
      DentalClinicService.getQuotes({
        employee_id: employee_id,
        ini_date,
        end_date,
        per_page: 9999,
        group: overLoad ? 2 : null,
      }),
      AdminService.getEmployeeSchedules({
        employee_id: employee_id,
        per_page: 1,
        date: ini_date,
      }),
      DentalClinicService.getDisableScheduleTimes({
        employee_id: employee_id,
        ini_time: ini_date,
        end_time: end_date,
      })
    ]);
    let currentSchedule = {};
    if (schedules.data.length > 0) {
      currentSchedule = schedules.data[0];
    }

    // this.loadDateRanges(); // cargado fechas de la semana [cols]
    let date_range = [];
    let itMoment = myMoment(ini_date);
    for (let it of [1, 2, 3, 4, 5, 6, 0]) {
      date_range.push({
        date: itMoment.format("YYYY-MM-DD"),
        dayOfWeek: it,
      });
      itMoment.add(1, "day");
    }


    let timeToBegin = "08:00";
    let timeToEnd = "23:59";
    // aqui vamos a obtener el maximo y minimo de horarios
    for (let sch of schedules.data) {
      timeToBegin = sch.times.reduce((p, c) => {
        if (c.ini_time >= c.end_time) return p;
        if (c.ini_time == null) return p;
        if (!sch[`d${c.day_of_week}`]) return p;
        return c.ini_time < p ? c.ini_time : p;
      }, "99:99:99");
    }
    if (timeToBegin == "99:99:99") timeToBegin = "08:00";
    // this.updateTimes(); // cargada lista de tiempos [rows]
    let day_times = dayTimes.filter((x, y) => {
      return (
        y % unit_time == 0 &&
        (timeToBegin ? timeToBegin <= x.value : true) &&
        (timeToEnd ? timeToEnd >= x.value : true)
      );
    });

    let times = {}; // el horario por claves
    quotes.data = quotes.data.filter(x => {
      return x.status_id !== 10 && x.status_id !== 9;
    });
    let quotesCounter = quotes.data.length;
    for (let it1 in day_times) {
      let row = day_times[it1];
      for (let col of date_range) {
        let timeKey = `${col.dayOfWeek}_${row.time}`;
        let currentDateTime = `${col.date} ${row.value}`;
        // se omiten citas anuladas y reagendadas
        let currentQuotes = quotes.data.filter(x => {
          return x.ini_date == `${col.date} ${row.value}`;
        }).sort((a, b) => {
          return a.group > b.group ? 1 : -1;
        });
        // si ya existe su registro, no deberia de hacer nada
        if (times[timeKey]) {
          // console.log("ommit in " + timeKey);
          times[timeKey].datetime = `${col.date} ${row.value}`;
          continue;
        }
        let firstQuote = currentQuotes.length > 0 ? currentQuotes[0] : {};
        let status = '';
        // checkea si el dia esta habilitado
        if (currentSchedule[`d${col.dayOfWeek}`]) {
          let timesOnDay = currentSchedule.times.filter(x =>
            x.day_of_week == col.dayOfWeek && x.ini_time <= row.value && x.end_time > row.value
          );
          if (timesOnDay.length > 0) {
            // console.log("row ini time end time : " + row.time, timesOnDay);
            status = 'available';
          }
        }
        if (currentQuotes.length > 0) {
          status = 'quoted';
        }
        if (
          disableTimes.data.length > 0
          && disableTimes.data.filter(
            x => x.ini_time <= currentDateTime && x.end_time > currentDateTime).length > 0
        ) {
          status = 'locked';
        }
        times[timeKey] = {
          quotes: currentQuotes,
          status,
          rowspan: firstQuote.intervals,
          datetime: currentDateTime
        };
        if (firstQuote.intervals > 1) {
          // console.log("need set intervals in future");
          for (let i2 = 1; i2 < firstQuote.intervals; i2++) {
            // got to next pos
            // console.log("set in pos " + timeKey + " // " + i2 + " // " + (it1 + i2));
            if (day_times[parseInt(it1) + parseInt(i2)]) {
              let nextPos = day_times[parseInt(it1) + parseInt(i2)];
              times[`${col.dayOfWeek}_${nextPos.time}`] = {
                quotes: [],
                status: 'avoid',
                rowspan: 0,
              }
            }
          }
        }

      }
    }
    return {
      args: arguments,
      date_range,
      times,
      day_times,
    };
  }
}

export default ScheduleService;
