<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2025-02-16 22:37 -->
<!-- Description:  -->
<template>
  <select class="custom-select" :value="value" @change="$emit('input', $event.target.value)">
    <option></option>
    <option v-for="(l, i) in list" :key="i" :value="l.id">{{ l.name }}</option>
  </select>
</template>
<script>
// script content
import {AdminService} from "../AdminService.js";

export default {
  name: "EmployeeSelect",
  components: {
    //
  },

  // directives

  props: {
    value: {},
    specialties: {default: ""},
  },

  data: () => ({
    list: [],
  }),

  computed: {
    //
  },

  // watch: {},

  beforeMount() {
    AdminService.getEmployees({
      specialties: this.specialties,
      per_page: 999,
    }).then((res) => {
      this.list = res.data;
    });
  },

  methods: {
    //
    //
    //
  }
};
</script>
<style scoped></style>
