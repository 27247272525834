<template>
  <div>
    <div class="alert alert-danger" v-show="errorMessage">
      <i class="fas fa-bell"></i> {{ errorMessage }}
    </div>

    <div class="row" v-show="phase == 1">
      <div class="col-md-4">
        <div class="form-group">
          <label for>Doctor</label>
          <EmployeeSelect :specialties="specialty_id" :value="employee_id" @input="employee_id = $event;
          getSchedule();
          "></EmployeeSelect>
        </div>
        <div class="form-group">
          <label for="">Grupo</label>
          <select class="custom-select" :value="group" @input="group = $event.target.value; getSchedule()">
            <option value="1">Agenda</option>
            <option value="2">
              💡 Sobreagenda</option>
          </select>
        </div>
        <div class="form-group">
          <label for="">Duracion</label>
          <DurationSelect v-model.number="duration" :key="schedule_interval" :interval="schedule_interval">
          </DurationSelect>
        </div>
        <button type="button" :disabled="errors" class="btn btn-primary" @click="save()">Guardar</button>
      </div>
      <div class="col" style="min-height: 480px">
        <div class="btn-group btn-block" role="group" aria-label="Basic example">
          <button @click.prevent="lastWeek(); getSchedule()" type="button" class="btn btn-light">
            <i class="fa fa-angle-left"></i>
          </button>
          <button type="button" class="btn btn-light text-capitalize">{{ currentTime.format("MMMM YYYY") }}</button>
          <button @click.prevent="nextWeek(); getSchedule()" type="button" class="btn btn-light">
            <i class="fa fa-angle-right"></i>
          </button>
        </div>
        <table class="table table-sm">
          <thead>
            <tr>
              <td v-for="(l, index) in weekDates" class="text-capitalize text-center" :key="index">
                <small>{{ l.date }}</small>
                <br>
                <small>{{ l.month }}</small>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-for="(l2, i2) in weekDates" :key="i2">
                <div v-for="(l3, i3) in times[l2.day]" :key="i3">
                  <a href="javascript:void(0)" class="d-block text-center mb-2" v-if="duration <= l3.maxDuration"
                    :class="{'bg-dark text-white': l3.datetime == selectedTime}" @blur="l3.selected = false"
                    @click.prevent="selectedTime = l3.datetime">{{ l3.time }}</a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row" v-show="phase == 2">
      <div class="col-md-2">
        <button class="btn btn-sm btn-primary" @click="reset()">
          <i class="fa fa-angle-left"></i> Volver
        </button>
      </div>
      <div class="col-md-6">
        <form @submit.prevent="save()">
          <div class="form-group">
            <label for>Fecha</label>
            <p class="form-control">{{ quote_time }}</p>
          </div>
          <div class="form-group">
            <label for>Cliente</label>
            <SelectClient ref="selCli" v-model="client_id"></SelectClient>
          </div>
          <div class="form-group">
            <label for>Comentario</label>
            <app-textarea v-model="commentary"></app-textarea>
          </div>
          <app-button-submit></app-button-submit>
        </form>
      </div>
    </div>
    <div class="row" v-if="phase == 3">
      <div class="col">
        <div class="alert alert-success">
          <h4>AGENDADO</h4>
          <p>
            <b>Doctor:</b>
            {{ $refs.selEmp.val.name }}
          </p>
          <p>
            <b>Cliente:</b>
            {{ $refs.selCli.val.name }}
          </p>
          <p>
            <b>Fecha:</b>
            {{ quote_time }}
          </p>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {DentalClinicService} from "../service";
import SelectClient from "../../store-module/clients/Select";
import DurationSelect from "./DurationSelect.vue";
import ScheduleService from "./ScheduleService.js";
import myMoment from "src/lt/utils/myMoment.js";
import EmployeeSelect from "src/lt/admin-module/employees/EmployeeSelect.vue";

export default {
  components: {
    // SelectMinutes,
    // TdTimeView,
    SelectClient,
    DurationSelect,
    EmployeeSelect,
    // SelectSpecialty
  },
  props: {
    p_client_id: {},
    treatment_plan_id: {}
  },
  data: () => ({
    employee_id: null,
    duration: null,
    selectedTime: null,
    // for week select
    ini_time: null,
    end_time: null,
    currentTime: null,
    list: [],
    day_times: [],
    weekDates: [],
    changesTime: 1,
    timeTobegins: null,
    timeToEnd: null,
    phase: 1,
    quote_time: null,
    client_id: null,
    commentary: null,
    plans: null,
    product_id: null,
    group: 1,
    preventDuration: 0,

    schedule_interval: 0,
    times: [],

    errorMessage: "",
    // specialty_id: null
  }),
  computed: {
    ...mapState({
      clientConfig: s => s.config.client,
      specialty_id: s => s.config.dental.treatments.specialty_id
    }),
    errors() {
      return false;
    }
  },

  created() {
    this.currentTime = myMoment(myMoment().format("YYYYMMDD"), "YYYYMMDD");
    this.updateTimes(this.currentTime);
  },
  methods: {

    /**
     * @param oTimeDeft time
     */
    setTime(time) {
      this.phase = 2;
      this.quote_time = time.tm_start;
      if (this.p_client_id) this.$refs.selCli.setValueFromId(this.p_client_id);
    },
    updateTimes() {
      let ini_time = this.currentTime
        .clone()
        .add(-this.currentTime.day()+1, "days");
      let end_time = ini_time.clone().add(7, "days");
      this.ini_time = ini_time.format("YYYY-MM-DD");
      this.end_time = end_time.format("YYYY-MM-DD");
      this.weekDates = [];
      for (let index = 0; index < 7; index++) {
        this.weekDates.push({
          day: ini_time.day().toString(),
          date: ini_time.format("dddd DD"),
          month: ini_time.format("MMM"),
        });
        ini_time.add(1, "days");
      }
    },
    save() {
      // validacion
      if (this.duration && this.employee_id && this.group && this.selectedTime) {
        this.errorMessage = "";
        DentalClinicService.saveQuote({
          reprogram_quote: 1,
          id: this.quote.id,
          ini_date: this.selectedTime,
          group: this.group,
          employee_id: this.employee_id,
          interval: this.schedule_interval,
          end_date: myMoment(this.selectedTime)
            .add(this.duration, "minute")
            .getDateTimeFormat()
        }).then(() => {
          this.$emit("saved");
        });
      } else {
        this.errorMessage = "Faltan datos";
        if (this.selectedTime) {
          this.errorMessage = "Selecciona un horario";
        }
      }
      // DentalClinicService.saveQuote({
      //   client_id: this.client_id,
      //   employee_id: this.employee_id,
      //   product_id: this.product_id,
      //   entity_id: this.entity_id,
      //   ini_date: this.quote_time,
      //   commentary: this.commentary,
      //   specialty_id: this.specialty_id,
      //   treatment_plan_id: this.treatment_plan_id,
      //   end_date: myMoment(this.quote_time)
      //     .add(this.duration, "minutes")
      //     .format("YYYY-MM-DD HH:mm")
      // }).then(res => {
      //   this.phase = 3;
      //   this.$emit("submitted", res);
      // });
    },
    setSpecialty(specialty) {
      this.$refs.selSpe.setValue(specialty);
      // this.specialty = specialty;
    },
    nextWeek() {
      this.currentTime.add(7, "days");
      this.changesTime++;
      this.updateTimes();
    },
    lastWeek() {
      this.currentTime.add(-7, "days");
      this.changesTime++;
      this.updateTimes();
    },
    getGroup() {
      return this.group;
    },
    setGroup(val) {
      this.group = val;
    },
    reset() {
      this.phase = 1;
      this.client_id = undefined;
      this.employee_id = undefined;
      this.product_id = undefined;
      this.entity_id = undefined;
      this.quote_time = undefined;
      this.commentary = undefined;
      this.duration = undefined;
      this.schedule_interval = null;
      this.day_times = [];
    },
    setComment(comment) {
      this.commentary = comment;
    },

    async getSchedule() {
      this.times = {};
      this.selectedTime = null;
      let results = await ScheduleService.generateAvailableSchedule(
        this.employee_id, this.ini_time, this.group == 2 ? true : false, this.schedule_interval
      );
      for (let key1 in results.times) {
        let listTimes = results.times[key1];
        if (Array.isArray(listTimes)) {
          for (let key2 in listTimes) {
            let currentTime = listTimes[key2];
            let dinIt = parseInt(key2);
            if (currentTime) {
              let currMoment = myMoment(currentTime.datetime);
              do {
                dinIt += 1;
                currMoment.add(this.schedule_interval, "minute");
                if (listTimes[dinIt] && !currMoment.isSame(listTimes[dinIt].datetime)) {
                  dinIt = listTimes.length;
                }
              } while (dinIt < listTimes.length);
              listTimes[key2].maxDuration = currMoment.diff(currentTime.datetime, "minute");
            }
          }
        }
      }
      // console.log("results", results);
      this.times = results.times;
      // this.times = {};
      this.day_times = results.day_times;
    },

    setPreventDuration(duration) {
      this.preventDuration = duration;
    },

    async setValue(reg) {
      this.quote = reg;
      this.duration = reg.duration;
      this.group = reg.group;
      await this.setEmployeeId(reg.employee_id);
      this.getSchedule();
    },

    async setEmployeeId(id) {
      this.employee_id = id;
      this.currentTime = myMoment(myMoment().format("YYYYMMDD"), "YYYYMMDD");
      this.updateTimes(this.currentTime);
      let res = await DentalClinicService.getEmployeeConfig(id);
      this.schedule_interval = res.interval_service || 5; // valor por defecto es min
      this.employee_id = id;
    },
  }
};
</script>

<style></style>
